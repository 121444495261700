import React from 'react'
import Layout from '../../components/Layout'
import { Helmet } from "react-helmet"
//import { withPrefix, Link } from "gatsby"
import { Link, withPrefix } from "gatsby"
//import facebook from '../../img/social/facebook.svg'
//import instagram from '../../img/social/instagram.svg'
import preventionGroup from '../../img/prevention-group.svg'
import treatmentGroup from '../../img/treatment-group.svg'

export default () => (
  <Layout>
  <section className="section section--gradient">
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">

          <div className="section">
          <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
        Becoming a Tester
          </h2>
          <p>Please see directions and timelines below to decide which study group you want to be in. Then cick on the button below to enroll.</p>

          <div style={{textAlign: 'center', backgroundColorx: "#ff0000", position: "relative", top:"28px"}} className="column is-3x ef-button-report-effectivenessx">
          <Link className="btn" to="/forms/tester" style={{marginTop: "0px", paddingTop: "9px", width: "210px", boxShadow: "none", backgroundColor: "#00693eff", color: "white"}}>
            Become a Tester
          </Link>
          </div>


          <div><br></br><br></br>
          <h3 className="title is-size-4">Prevention Group</h3>
          <img
            src={preventionGroup}
            alt="Prevention Group"
            style={{ width: 'auto', height: 'auto' }}
          />
          </div>

          <div><br></br><br></br>
          <h3 className="title is-size-4">Treatment Group</h3>
          <img
            src={treatmentGroup}
            alt="Treatment Group"
            style={{ width: 'auto', height: 'auto' }}
          />
          </div>

          <div align="center">
          <Helmet>
            <script src={withPrefix('subscribe-jotform.js')} type="text/javascript" />
          </Helmet>




          </div>



          </div>

        </div>

      </div>
    </div>
</section>
  </Layout>
)
